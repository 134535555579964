import * as React from 'react';
import { navigate } from 'gatsby';

const TrustPage: React.FunctionComponent<{}> = () => {
  React.useEffect(() => {
    navigate('/');
  }, []);
  return (
    <div />
  );
};

export default TrustPage;
